/*
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/22/20, 10:47 AM
 *
 */

import { useEffect, useRef, useState } from "react";
import "./LogoBox.css";
import { useIsFetching } from "@tanstack/react-query";
// import { gsap } from "gsap";
// import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import AuthService from "../services/auth.service";
import MultiTenantService from "../services/multi-tenant.service";
import { useQuery } from "@tanstack/react-query";

// gsap.registerPlugin(DrawSVGPlugin);
// const debug = require("debug")("NeoGen:LogoBox");

/**
 * @component
 */
function LogoBox(props: LogoBoxProps) {
    const isFetching = useIsFetching();
    const tl = useRef(null);
    // const location = useLocation();
    const [image, setImage] = useState("");
    const [imageSmall, setImageSmall] = useState("");

    const mtQuery = useQuery(
        ["MultiTenant"],
        async () => {
            const result = await MultiTenantService.getAll();
            if (result) {
                return result.data;
            }
        },
        { staleTime: 1000 * 60 * 5, cacheTime: 1000 * 60 * 5, refetchOnMount: true, refetchOnWindowFocus: false },
    );

    useEffect(() => {
        if (mtQuery.isSuccess) {
            setImage(
                mtQuery.data?.find((i: any) => i.url === window.location.hostname)?.logo ??
                    "<img src='/img/ClearERC.png' class='h-8' />",
            );
            setImageSmall(
                mtQuery.data?.find((i: any) => i.url === window.location.hostname)?.smallLogo ??
                    "<img src='/img/ClearERC.png' class='h-6' />",
            );
            document.title = mtQuery.data?.find((i: any) => i.url === window.location.hostname)?.name ?? "ClearERC";
        }
        console.log(window.location.hostname);
    }, [mtQuery.data, mtQuery.isSuccess]);

    return (
        <div className="p-0 m-0">
            {mtQuery.isSuccess && <>{/* <PrintPre>{newImage}</PrintPre> */}</>}
            {/* <PrintPre>{mtQuery}</PrintPre> */}
            <span className={"md:p-1 hidden md:inline-block"} dangerouslySetInnerHTML={{ __html: image }} />
            <span
                className={"m-0 p-0 md:hidden"}
                dangerouslySetInnerHTML={{ __html: props.large ? image : imageSmall }}
            />
        </div>
    );
}

type LogoBoxProps = {
    large?: boolean;
};

export default LogoBox;
